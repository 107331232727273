/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import React from 'react';
import { Routes, Route } from "react-router-dom";
import { AboutPage } from './pages/AboutPage';
import { InventoryPage } from './pages/InventoryPage';
import { ThemeProvider } from '@emotion/react'
import ProductPage from "./pages/ProductPage";
import { ShoppingCartPage } from "./pages/ShoppingCartPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { CheckoutPage } from "./pages/CheckoutPage";
import { RegisterPage } from "./pages/RegisterPage";
import { LoginPage } from "./pages/LoginPage";
import { ResetPasswordPage } from "./pages/ResetPasswordPage"
import { useLocaleState } from "./hooks/useLocaleState";
import { useThemeState } from "./hooks/useThemeState";
//import { ErrorBoundary } from "./components/ErrorBoundary";
//import { useErrorBoundaryState } from "./hooks/useErrorBoundaryState";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { CheckoutCompletePage } from "./pages/CheckoutCompletePage";
import { useAppConfigState } from "./hooks/useAppConfigState";
import LoadingPage from "./pages/LoadingPage";
import { useSiteLayoutState } from "./hooks/useSiteLayoutState";
import { motion, AnimatePresence } from "framer-motion";
import { ErrorDialog } from "./components/ErrorDialog";
import { useCheckoutFormState } from "./hooks/useCheckoutFormState";
import { useCatalogState } from "./hooks/useCatalogState";
import { closeSnackbar, SnackbarKey, SnackbarProvider } from "notistack";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import PrintProductPage from "./pages/PrintProductPage";
import { useHookstateEffect } from "@hookstate/core";
import { useStorefrontSettingsState } from "./hooks/useStorefrontSettingsState";
import { useSessionState } from "./hooks/useSessionState";
import { useInterval } from "./hooks/useInterval";
import { HomePage } from "./pages/HomePage";


const App: React.FC = () => {
    //const errorBoundaryState = useErrorBoundaryState();
    const appConfigState = useAppConfigState();
    const appConfig = appConfigState.get().appConfig;
    const appConfigIsLoading = appConfigState.get().isLoading;
    const checkoutUrl = appConfigState.getCheckoutUrl();
    const localeState = useLocaleState();
    const themeState = useThemeState();
    const settingsState = useStorefrontSettingsState();
    const settings = settingsState.get().settings;
    const isLoadingSettings = settingsState.get().isLoading;
    const separateQuoteRequestsByCatalog = settingsState.get().settings?.SeparateQuoteRequestsByCatalog;
    const siteLayoutState = useSiteLayoutState();
    const sessionState = useSessionState();
    const loggedIn = sessionState.get().loggedIn;
    const storefrontSettingsState = useStorefrontSettingsState();
    const isAnonymousAccessEnabled = storefrontSettingsState.get().settings?.IsAnonymousAccessEnabled ?? false;

    const quoteRequestFormState = useCheckoutFormState();
    const lookuplocation = quoteRequestFormState.get().lookuplocation;
    const isLoadingLocation = quoteRequestFormState.get().isLoadingLocation;
    //const lookupwarehouse = quoteRequestFormState.get().lookupwarehouse;
    //const isLoadingWarehouse = quoteRequestFormState.get().isLoadingWarehouse;

    const catalogState = useCatalogState();
    const catalogs = catalogState.get().catalogs;
    const isLoadingCatalogs = catalogState.get().isLoadingCatalogs;

    //const checkoutFormState = useCheckoutFormState();
    //let warehouseId = checkoutFormState.get().warehouseIdValue;

    const appCss = css`
        color: ${themeState.get().theme.palette.siteTextPrimary};
    `;

    // set swiper css variable at startup
    useHookstateEffect(() => {
        document.documentElement.style.setProperty('--swiper-theme-color', themeState.isDark() ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)');
    }, []);

    // every minute check to see if the catalogState is expired on reload the list of catalogs
    // MV 2024-04-05 the refresh of the catalogs was causing the popup to close on the product page.
    //useInterval(() => {
    //    if (catalogState.isExpired()) {
    //        catalogState.loadAsync();
    //    }
    //}, 60 * 1000);

    // load the AppConfig and set the theme
    useHookstateEffect(() => {
        (async () => {
            try {
                if (!appConfig && !appConfigIsLoading) {
                    await appConfigState.loadAsync();
                    themeState.set(themeState.get().themeName);
                }
            } catch (error) {
                siteLayoutState.handleErrorAsync(error);
            }
        })();
    }, [appConfig, appConfigIsLoading]);

    // Load the StorefrontSettings
    useHookstateEffect(() => {
        (async () => {
            try {
                if (appConfig && !settings && !isLoadingSettings) {
                    await settingsState.loadAsync();
                }
            } catch (error) {
                siteLayoutState.handleErrorAsync(error);
            }
        })();
    }, [appConfig, settings, isLoadingSettings]);

    // Load the Catalogs
    useHookstateEffect(() => {
        (async () => {
            try {
                if (appConfig && !catalogs && !isLoadingCatalogs && settings && ((settings.IsAnonymousAccessEnabled) || loggedIn)) {
                    await catalogState.loadAsync();
                }
            } catch (error) {
                siteLayoutState.handleErrorAsync(error);
            }
        })();
    }, [appConfig, catalogs, isLoadingCatalogs, loggedIn, settings]);

    // Default the Location
    useHookstateEffect(() => {
        (async () => {
            try {
                if (catalogs && appConfig && !lookuplocation && !isLoadingLocation) {
                    await quoteRequestFormState.loadLocationsAsync();
                    const lookuplocation = quoteRequestFormState.get().lookuplocation;
                    if (lookuplocation && lookuplocation.length) {
                        const currentLocationId = quoteRequestFormState.get().locationIdValue;
                        const currentLocation = lookuplocation.find(l => l.LocationId === currentLocationId);
                        if (!currentLocation) {
                            if (catalogs.length > 0 && catalogs[0].LocationId) {
                                const catalog = catalogs[0];
                                quoteRequestFormState.setLocationId(catalog.LocationId);
                                quoteRequestFormState.setWarehouseId(catalog.WarehouseId);
                            }
                            else {
                                const firstLocation = lookuplocation[0];
                                quoteRequestFormState.setLocationId(firstLocation.LocationId);
                                quoteRequestFormState.setWarehouseId(firstLocation.WarehouseId);
                            }
                        }
                    }
                    else {
                        quoteRequestFormState.setLocationId('');
                        quoteRequestFormState.setWarehouseId('');
                    }
                }
            } catch (error) {
                siteLayoutState.handleErrorAsync(error);
            }
        })();
    }, [appConfig, lookuplocation, isLoadingLocation, catalogs]);

    // Default the Warehouse
    //useHookstateEffect(() => {
    //    (async () => {
    //        try {
    //            if (catalogs && appConfig && catalogs && !lookupwarehouse && !isLoadingWarehouse) {
    //                await quoteRequestFormState.loadWarehousesAsync();
    //                const lookupwarehouse = quoteRequestFormState.get().lookupwarehouse;
    //                if (lookupwarehouse && lookupwarehouse.length) {
    //                    if (warehouseId) {
    //                        // try to default the warehouseid from checkoutFormState
    //                        let warehouse = lookupwarehouse.find(w => w.WarehouseId === warehouseId);
    //                        if (warehouse) {
    //                            quoteRequestFormState.setWarehouseId(warehouse?.WarehouseId ?? '');
    //                        }
    //                        else {
    //                            if (catalogs && catalogs.length) {
    //                                // try to default the warehouse from the first webcatalog
    //                                warehouse = lookupwarehouse.find(w => w.WarehouseId === catalogs[0].WarehouseId);
    //                                if (warehouse) {
    //                                    quoteRequestFormState.setWarehouseId(warehouse?.WarehouseId ?? '');
    //                                }
    //                                else {
    //                                    // default the warehouse from the first warehouse in the lookup
    //                                    quoteRequestFormState.setWarehouseId(lookupwarehouse[0].WarehouseId);
    //                                }
    //                            }
    //                            else {
    //                                // default the warehouse from the first warehouse in the lookup
    //                                quoteRequestFormState.setWarehouseId(lookupwarehouse[0].WarehouseId);
    //                            }
    //                        }
    //                    }
    //                    else {
    //                        // default the warehouse from the first warehouse in the lookup
    //                        if (catalogs.length > 0 && catalogs[0].WarehouseId) {
    //                            quoteRequestFormState.setWarehouseId(catalogs[0].WarehouseId);
    //                        } else {
    //                            quoteRequestFormState.setWarehouseId(lookupwarehouse[0].WarehouseId);
    //                        }
    //                    }
    //                }
    //                else {
    //                    // the lookup didn't have warehouses, so blank out the warehouseid
    //                    quoteRequestFormState.setWarehouseId('');
    //                }
    //            }
    //        } catch (error) {
    //            siteLayoutState.handleErrorAsync(error);
    //        }
    //    })();
    //}, [appConfig, lookupwarehouse, isLoadingWarehouse, warehouseId, catalogs]);

    return (<>
        <ThemeProvider theme={themeState.getTheme()}>
            <div
                className="App"
                css={appCss}
                data-theme={themeState.get().themeName}
                data-language={localeState.get().language}>
                {/*<ErrorBoundary*/}
                {/*    errorBoundaryState={errorBoundaryState}*/}
                {/*    fallback={(<p>Oops something went wrong!</p>)}>*/}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {appConfigState.get().appConfig && settings && (
                            <Routes>
                            {/*<Route path="/" element={<HomePage />} />
                            <Route path="/" element={<InventoryPage showlandingpage={true} />} />*/}
                            {(isAnonymousAccessEnabled || loggedIn) && (<>
                                <Route path="/" element={<HomePage />} />
                                <Route path="about" element={<AboutPage />} />
                                <Route path="register" element={<RegisterPage />} />
                                <Route path="inventory/:catalogid/index" element={<InventoryPage showlandingpage={true} />} />
                                <Route path="inventory/:catalogid" element={<InventoryPage showlandingpage={false} />} />
                                <Route path="inventory/:catalogid/:departmentid" element={<InventoryPage showlandingpage={false} />} />
                                <Route path="inventory/:catalogid/:departmentid/:categoryid" element={<InventoryPage showlandingpage={false} />} />
                                <Route path="inventory/:catalogid/:departmentid/:categoryid/:subcategoryid" element={<InventoryPage showlandingpage={false} />} />
                                <Route path="login" element={<LoginPage />} />
                                <Route path="resetpassword/:email/:resettoken" element={<ResetPasswordPage type="resetpassword" />} />
                                <Route path="completeregistration/:email/:resettoken" element={<ResetPasswordPage type="completeregistration" />} />
                                <Route path="changepassword/:email/:resettoken" element={<ResetPasswordPage type="changepassword" />} />
                                <Route path="product/:productid/warehouseid/:warehouseid/locationid/:locationid/fromdate/:fromdate/todate/:todate" element={<ProductPage />} />
                                <Route path="printproduct/:productid/warehouseid/:warehouseid/locationid/:locationid/fromdate/:fromdate/todate/:todate" element={<PrintProductPage />} />
                                <Route path="shoppingcart" element={<ShoppingCartPage />} />
                                <Route path="checkoutcomplete" element={<CheckoutCompletePage />} />
                                {!separateQuoteRequestsByCatalog && (<>
                                    <Route path={checkoutUrl} element={<CheckoutPage />} />
                                </>)}
                                {separateQuoteRequestsByCatalog && (<>
                                    <Route path={`${checkoutUrl}/catalogid/:catalogid`} element={<CheckoutPage />} />
                                </>)}
                            </>)}
                            {(isAnonymousAccessEnabled || loggedIn) && (<>
                                <Route path="*" element={<NotFoundPage />} />
                            </>)}
                            {(!isAnonymousAccessEnabled && !loggedIn) && (<>
                                <Route path="*" element={<LoginPage />} />
                            </>)}
                            </Routes>
                        )}
                        {((!appConfigState.get().appConfig) || (!settings)) && (
                            <Routes>
                                <Route path="*" element={<LoadingPage />} />
                            </Routes>
                        )}
                        {siteLayoutState.get().errorMessage && (
                            <AnimatePresence>
                                <motion.div layoutId="errordialog">
                                    <ErrorDialog />
                                </motion.div>
                            </AnimatePresence>
                    )}
                    <SnackbarProvider
                        action={(snackbarId: SnackbarKey) => (
                            <IconButton aria-label="Dismiss"
                                onClick={async e => {
                                    return Promise.resolve().then(() => {
                                        closeSnackbar(snackbarId)
                                    });
                                }}>
                                <Close />
                            </IconButton>
                        )}
                    />
                    </LocalizationProvider>
                {/*</ErrorBoundary>*/}
            </div>
        </ThemeProvider>
    </>);
}

export default App;
