/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { ShoppingCartItemModel, useShoppingCartItemsState } from "../../../hooks/useShoppingCartItemsState";
import { useTranslate } from "../../../hooks/useTranslate/useTranslate";
import { ShoppingCartViewSettings } from "../ShoppingCartControl";
import { ShoppingCartPackageItemIncludedDesktop } from "./ShoppingCartPackageItemIncludedDesktop";
import { ShoppingCartPackageItemOptionalDesktop } from "./ShoppingCartPackageItemOptionalDesktop";
import { PackageItem } from "../../../hooks/useInventoryState";
import { ImmutableObject } from "@hookstate/core";

//------------------------------------------------------------------------------------
export type ShoppingCartPackageItemsDesktopProps = {
    shoppingCartItem: ShoppingCartItemModel;
    index: number;
    viewSettings: ShoppingCartViewSettings;
}
export const ShoppingCartPackageItemsDesktop: React.FC<ShoppingCartPackageItemsDesktopProps> = props => {
    const translate = useTranslate();
    const product = props.shoppingCartItem.product;
    const packageItems = product.PackageItems ?? [];
    const shoppingCartItemsState = useShoppingCartItemsState();
    let enableQty = (shoppingCartItemsState.getQty(product) > 0);
    const showDailyRate = (props.viewSettings.hasRates && props.viewSettings.hasDailyRate);
    const showWeeklyRate = (props.viewSettings.hasRates && props.viewSettings.hasWeeklyRate);
    const showMonthlyRate = (props.viewSettings.hasRates && props.viewSettings.hasMonthlyRate);

    const includedPackageItems: ImmutableObject<PackageItem>[] = [];
    const optionalPackageItems: ImmutableObject<PackageItem>[] = [];
    if (packageItems) {
        for (let packageItem of packageItems) {
            if (packageItem.IsOption) {
                optionalPackageItems.push(packageItem);
            }
            else {
                includedPackageItems.push(packageItem);
            }
        }
    }
    {
        packageItems && packageItems.map((item) => {
            if (item.IsOption) {
                return (
                    <ShoppingCartPackageItemOptionalDesktop
                        key={item.InventoryId}
                        packageProduct={product}
                        packageItem={item}
                        enableQty={enableQty}
                        viewSettings={props.viewSettings} />
                )
            }
            return null;
        })
    }

    const styles = css`
        display:flex;
        flex-wrap: wrap;
        align-items: flex-start;
        table.packageitems {
            margin:.25rem .5rem;
            font-size: .8rem;
            border-collapse: collapse;
        }
        @media only screen and (max-width: 720px) {
            table.packageitems {
                width: 100%;
            }
        }
        tr.headerrow td {
            padding: 0 .25rem .1rem .25rem;
            white-space: no-wrap;
            font-weight: bold;
        }
        tbody.included tr.headerrow {
            border-bottom: 1px solid #000000;
        }
        tbody.optional tr.headerrow {
            border-bottom: 1px solid #000000;
        }
        tr.packageitemrow {
            font-size:.7rem;
        }
        tbody.included .col-legend {
            /*background-color: #ffc107;
            width: 6px;*/
        }
        tbody.optional .col-legend {
            /*background-color: #8bc34a;
            width: 6px;*/
        }
        .col-image {
            padding: 0 .25rem;
        }
        .col-description {
            width: 100%;
            padding: 0 .5rem;
        }
        tr.headerrow td.col-qty {
            text-align: center;
        }
        tr.packageitemrow td.col-qty {
            text-align: center;
        }
        td.col-qty .MuiInputBase-root {
            text-align: right;
        }
        .col-dailyrate
        {
            text-align: right;
            padding: 0 .25em;
        }
        .col-weeklyrate {;
            text-align: right;
        }
        .col-monthlyrate {
            text-align: right;
        }
    `;

    return (<>
        {product && (
            <div css={styles}>
                {/*{(packageItems && (packageItems.length > 0)) && (*/}
                {/*    <div className="accessories-caption">Package Includes:</div>*/}
                {/*)}*/}

                {/* Package Includes */}
                {includedPackageItems.length > 0 && (<>
                    <table className="packageitems included">
                        <tbody className="included">
                            <tr className="headerrow">
                                {/*<td className="col-legend" colSpan={1}></td>*/}
                                <td className="col-description" colSpan={2}>{translate('Package includes')}</td>
                                {/*<td className="col-tags">Tags</td>*/}
                                <td className="col-qty">{translate('Quantity')}</td>
                                {showDailyRate && (
                                    <td className="col-dailyrate">{translate('Daily')}</td>
                                )}
                                {showWeeklyRate && (
                                    <td className="col-weeklyrate">{translate('Weekly')}</td>
                                )}
                                {showMonthlyRate && (
                                    <td className="col-monthlyrate">{translate('Monthly')}</td>
                                )}
                                {/*<td className="col-availability">Availability</td>*/}
                            </tr>
                            {includedPackageItems.map((item) => {
                                if (item.IsOption) return null;
                                return (
                                    <ShoppingCartPackageItemIncludedDesktop
                                        key={item.InventoryId}
                                        packageProduct={product}
                                        packageItem={item}
                                        enableQty={enableQty}
                                        viewSettings={props.viewSettings} />
                                )
                            })}
                        </tbody>
                    </table>
                </>)}
                {optionalPackageItems.length > 0 && (<>
                    <table className="packageitems optional">
                        <tbody className="optional">
                            <tr className="headerrow">
                                {/*<td className="col-legend" colSpan={1}></td>*/}
                                <td className="col-description" colSpan={2}>{translate('Optional accessories')}</td>
                                {/*<td className="col-tags">Tags</td>*/}
                                <td className="col-qty">{translate('Quantity')}</td>
                                {showDailyRate && (
                                    <td className="col-dailyrate">{translate('Daily')}</td>
                                )}
                                {showWeeklyRate && (
                                    <td className="col-weeklyrate">{translate('Weekly')}</td>
                                )}
                                {showMonthlyRate && (
                                    <td className="col-monthlyrate">{translate('Monthly')}</td>
                                )}
                                {/*<td className="col-availability">Availability</td>*/}
                            </tr>
                            {optionalPackageItems.map((item) => {
                                if (item.IsOption) {
                                    return (
                                        <ShoppingCartPackageItemOptionalDesktop
                                            key={item.InventoryId}
                                            packageProduct={product}
                                            packageItem={item}
                                            enableQty={enableQty}
                                            viewSettings={props.viewSettings} />
                                    )
                                }
                                return null;
                            })}
                        </tbody>
                    </table>
                </>)}


                {/*{packageItems && packageItems.map((item) => (*/}
                {/*    <ProductDetailsPackageItem key={item.InventoryId} packageProduct={product} packageItem={item} allowAddingUnavailableItems={props.allowAddingUnavailableItems} enableQty={enableQty} viewSettings={props.viewSettings} />*/}
                {/*))}*/}
            </div>
        )}
    </>);
}

