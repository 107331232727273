/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { ImmutableObject } from "@hookstate/core";
import { KeyboardArrowRight } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useCheckoutFormState } from "../../../hooks/useCheckoutFormState";
import { Product, useInventoryState } from "../../../hooks/useInventoryState";
import { useThemeState } from "../../../hooks/useThemeState";

// ListView - Links for: department > category > subcategory
export type ProductBrowserListItemLinksProps = {
    product: ImmutableObject<Product>;
}
export const ProductBrowserListItemDepartmentCategorySubcategoryLinks: React.FC<ProductBrowserListItemLinksProps> = props => {
    const product = props.product;
    const themeState = useThemeState();
    const theme = themeState.get().theme;
    const inventoryState = useInventoryState();
    const checkoutFormState = useCheckoutFormState();
    const warehouse = checkoutFormState.getWarehouse();

    return (<>
        {/* links for: department > category > subcategory */}
        <div css={css`display:flex;font-size:.7rem;padding:.5rem .5rem;`}>
            <div css={css`display:flex;align-items:center;`}>
                <span css={css`flex:0 0 auto;`}>{product.WebCatalog ?? ''}</span>
                {/*<Link
                    to={`/inventory/${product.WebCatalogId}/index`}
                    css={css`
                                flex:0 0 auto;
                                cursor:pointer;
                                color: ${theme.palette.siteTextPrimary};
                                text-decoration: none;
                                &:hover {
                                    text-decoration: underline;
                                }`}
                >{product.WebCatalog ?? ''}</Link>*/}
                <KeyboardArrowRight css={css`font-size:.9em;`} />
                <span css={css`flex:0 0 auto;`}></span>{product.InventoryDepartment ?? ''}
                {/*<Link
                    to={`/inventory/${product.WebCatalogId}/${product.InventoryDepartmentId}`}
                    css={css`
                                    flex:0 0 auto;
                                    cursor:pointer;
                                    color: ${theme.palette.siteTextPrimary};
                                    text-decoration: none;
                                    &:hover {
                                        text-decoration: underline;
                                    }`}
                >{product.InventoryDepartment ?? ''}</Link>*/}
                <KeyboardArrowRight css={css`font-size:.9em;`} />
                <span css={css`flex:0 0 auto;`}>{product.Category ?? ''}</span>
                {/*<Link
                    to={`/inventory/${product.WebCatalogId}/${product.InventoryDepartmentId}/${product.CategoryId}`}
                    css={css`
                                    flex:0 0 auto;
                                    cursor:pointer;
                                    color: ${theme.palette.siteTextPrimary};
                                    text-decoration: none;
                                    &:hover {
                                        text-decoration: underline;
                                    }`}
                    onClick={async e => {
                        return Promise.resolve().then(() => {
                            inventoryState.setProductFilterAsync({
                                warehouse: warehouse,
                                searchText: '',
                                showLandingPage: false
                            })
                        });
                    }}
                >{product.Category ?? ''}</Link>*/}
                {product.SubCategoryId && (<>
                    <KeyboardArrowRight css={css`font-size:.9em;`} />
                    <span css={css`flex:0 0 auto;`}>{product.SubCategory ?? ''}</span>
                    {/*<Link
                        to={`/inventory/${product.WebCatalogId}/${product.InventoryDepartmentId}/${product.CategoryId}/${product.SubCategoryId}`}
                        css={css`
                                        flex:0 0 auto;
                                        cursor:pointer;
                                        color: ${theme.palette.siteTextPrimary};
                                        text-decoration: none;
                                        &:hover {
                                            text-decoration: underline;
                                        }`}
                    >{product.SubCategory ?? ''}</Link>*/}
                </>)}
            </div>
        </div>
    </>);
}