/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Link } from "react-router-dom";
import { useThemeState } from "../../hooks/useThemeState";
import { useInventoryState } from "../../hooks/useInventoryState";
import { useStorefrontSettingsState } from "../../hooks/useStorefrontSettingsState";
import { useSessionState } from "../../hooks/useSessionState";

export const BreadCrumbs = () => {
    const inventoryState = useInventoryState();
    const settingsState = useStorefrontSettingsState();
    const isLoadingSettings = settingsState.get().isLoading;
    const settings = settingsState.get().settings;
    const sessionState = useSessionState();
    const loggedIn = sessionState.get().loggedIn;
    const themeState = useThemeState();
    const theme = themeState.getTheme();
    const catalog = inventoryState.get().catalog;
    const department = inventoryState.get().department;
    const category = inventoryState.get().category;
    const subCategory = inventoryState.get().subCategory;
    //const isLoadingCatalog = ((catalog !== undefined) && (department === undefined)) ?? false;
    const isLoadingDepartment = ((department !== undefined) && (category === undefined)) ?? false;
    const isLoadingCategoryWithSubCategories = ((category !== undefined) && (!subCategory) && category.SubCategories && category.SubCategories.length > 0) ?? false;

    let catalogUrl = '';
    let departmentUrl = '';
    let categoryUrl = '';

    if (!settings || isLoadingSettings || (settings && !settings.IsAnonymousAccessEnabled && !loggedIn)) {
        return null;
    }

    if (catalog) {
        catalogUrl = `/inventory/${catalog?.Id}`;
        if (department) {
            departmentUrl = catalogUrl + `/${department?.Id}`;
            if (category) {
                categoryUrl = departmentUrl + `/${category?.Id}`;
            }
        }
    }

    return (<div css={css`flex:1 1 0;display:flex;flex-wrap:wrap;padding:0 1em;font-weight:700;align-items:center;`}>
        {/*<CatalogMenu />*/}
        {(catalog && !department) && (<>
            <div css={css`color:${theme.palette.primary.main};`}>{catalog.Name}</div>
        </>)}
        {(catalog && department) && (<>
            {catalog.Name}
            {/*<Link
                to={catalogUrl}
                css={css`
                    color:${theme.palette.primary.main};
                    text-decoration:none;
                    &:hover {
                        text-decoration:underline;
                    }`}>
                {catalog.Name}
            </Link>*/}
        </>)}

        {department && !category && isLoadingDepartment && (<>
            &nbsp;/&nbsp;<div>{department.Name}</div>
        </>)}
        {department && category && (<>
            &nbsp;/&nbsp;
            {department.Name}
            {/*<Link
                to={departmentUrl}
                css={css`
                    color:${theme.palette.primary.main};
                    text-decoration:none;
                    &:hover {
                        text-decoration:underline;
                    }`}>
                {department.Name}
            </Link>*/}
        </>)}

        {category && !subCategory && !isLoadingCategoryWithSubCategories && (<>
            <div css={css`flex:0 0 auto;margin:0 .5em;color:${theme.palette.primary.main};`}>/</div>
            <div css={css`color:${theme.palette.primary.main};`}>{category.Name}</div>
        </>)}
        {category && subCategory && (<>
            <div css={css`flex:0 0 auto;margin:0 .5em;color:${theme.palette.siteTextPrimary};`}>/</div>
            {category.Name}
            {/*<Link
                to={categoryUrl}
                css={css`
                    color:${theme.palette.primary.main};
                    text-decoration:none;
                    &:hover {
                        text-decoration:underline;
                    }`}>
                {category.Name}
            </Link>*/}
        </>)}


        {subCategory && (<>
            <div css={css`flex:0 0 auto;margin:0 .5em;color:${theme.palette.siteTextPrimary};`}>/</div>
            <div css={css`color:${theme.palette.primary.main};`}>{subCategory.Name}</div>
        </>)}
    </div>);
}