/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"

import { SiteLayout } from "../components/SiteLayout";

// About Page
export type AboutPageProps = {
    
}
export const AboutPage: React.FC<AboutPageProps> = props => {

    return (<>
        <SiteLayout 
            title="About"
            mainChildren={<>
                <div css={css``}>About</div>
            </>}
        />
    </>);
}
