import { hookstate, ImmutableObject, none, State, useHookstate } from '@hookstate/core'
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { ShoppingCartItemsState, ShoppingCartItemModel, useShoppingCartItemsState } from './useShoppingCartItemsState';
import clone from 'clone';
import { SessionState, useSessionState } from './useSessionState';
import { devtools } from '@hookstate/devtools';
import { LocaleState, useLocaleState } from './useLocaleState';
import dayjs from 'dayjs';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AppConfigState, useAppConfigState } from './useAppConfigState';
import { SiteLayoutState, useSiteLayoutState } from './useSiteLayoutState';
import { enqueueSnackbar } from 'notistack';
import { StorefrontSettingsState, useStorefrontSettingsState } from './useStorefrontSettingsState';
import { TranslateFunction, useTranslate } from './useTranslate/useTranslate';

// the portion of CheckoutFormModel that is stored in local storage
export class CheckoutFormLsModel {
    fromDateValue: string = dayjs().startOf('day').format('YYYY-MM-DD');
    toDateValue: string = dayjs().startOf('day').add(6, 'day').format('YYYY-MM-DD');
    locationIdValue: string = '';
    warehouseIdValue: string = '';
    dealIdValue: string = '';
    quoteDescription: string = '';
    jobName: string = '';
    notesValue: string = '';
    fromDateByCatalogId: { [key: string]: string } = {};
    toDateByCatalogId: { [key: string]: string } = {};
;
}

export class Deal {
    DealId: string = '';
    Deal: string = '';
}

export class CheckoutFormModel extends CheckoutFormLsModel {
    isValid: boolean = false;
    hasSubmitError: boolean = false;
    submitError: string = '';
    isSubmitted: boolean = false;
    isSubmitting: boolean = false;

    fromDateCaption: string = 'From Date';
    fromDateHasError: boolean = false;
    fromDateError: string = '';
    fromDateRequiredError: string = 'From Date is required';

    toDateCaption: string = 'To Date';
    toDateHasError: boolean = false;
    toDateError: string = '';
    toDateRequiredError: string = 'Stop Date is required';

    isLoadingDeals = false;
    isLoadingDealsFailed = false;
    deals?: Deal[];

    isLoadingLocation: boolean = false;
    lookuplocation?: WarehouseLocation[];

    isLoadingWarehouse: boolean = false;
    lookupwarehouse?: Warehouse[];

    notesCaption: string = 'Notes';

    itemsValue?: ShoppingCartItemModel[];

    progressMeterSessionId?: string;
    progressMeterOpen: boolean = false;
}


const storefront_checkoutform_key = 'storefront-checkoutform';
let initialLsState = new CheckoutFormLsModel();
let initialState = new CheckoutFormModel();
const localstorageJson = window.localStorage.getItem(storefront_checkoutform_key);
if (localstorageJson) {
    try {
        const today = dayjs().startOf('day');
        initialLsState = JSON.parse(localstorageJson);
        let updateLsState = false;
        if (dayjs(initialLsState.fromDateValue).isBefore(today)) {
            initialLsState.fromDateValue = today.format('YYYY-MM-DD');
            initialLsState.toDateValue = dayjs(initialLsState.fromDateValue).add(6, 'day').format('YYYY-MM-DD');
            updateLsState = true;
        }
        if (dayjs(initialLsState.toDateValue).isBefore(initialLsState.fromDateValue)) {
            initialLsState.toDateValue = dayjs(initialLsState.fromDateValue).add(6, 'day').format('YYYY-MM-DD');
            updateLsState = true;
        }
        if (updateLsState) {
            window.localStorage.setItem(storefront_checkoutform_key, JSON.stringify(initialLsState));
        }
    }
    catch (ex) {
        console.error(ex);
    }
}
else {
    window.localStorage.setItem(storefront_checkoutform_key, JSON.stringify(initialLsState));
}
Object.assign(initialState, initialLsState);
const checkoutFormGlobalStore = hookstate<CheckoutFormModel>(initialState, devtools({ key: 'CheckoutFormState' }));

export class ApiQuoteRequest {
    WebCatalogId?: string;
    Language?: 'en'| 'es'| 'fw'|string;
    DepartmentId?: string;
    LocationId?: string;
    WarehouseId?: string;
    DealId?: string;
    QuoteDescription?: string;

    JobName?: string;
    StartDate?: string;
    StopDate?: string;
    Notes?: string;
    Items?: ApiQuoteRequestItem[];
}

export class ApiQuoteRequestItem {
    InventoryId?: string;
    //CatalogProductId?: string;
    WebCatalogId?: string;
    Qty?: number;
    PackageItems?: ApiQuoteRequestPackageItem[];
}

export class ApiQuoteRequestPackageItem {
    Lineage?: string; // the parent packageid
    InventoryId?: string;
    InventoryPackageInventoryId?: string;
    Qty?: number;
}

export class CheckoutFormState {
    private appConfigState: AppConfigState;
    private localeState: LocaleState;
    private sessionState: SessionState;
    private checkoutFormState: State<CheckoutFormModel>;
    private shoppingCartItemsState: ShoppingCartItemsState;
    private navigate: NavigateFunction;
    private siteLayoutState: SiteLayoutState;
    private storefrontSettingsState: StorefrontSettingsState;
    private translate: TranslateFunction;

    get(): ImmutableObject<CheckoutFormModel> {
        return this.checkoutFormState.get();
    }

    constructor(appConfigState: AppConfigState, localeState: LocaleState, sessionState: SessionState, checkoutFormState: State<CheckoutFormModel>, shoppingCartItemsState: ShoppingCartItemsState, navigate: NavigateFunction, siteLayoutState: SiteLayoutState, storefrontSettingsState: StorefrontSettingsState, translate: TranslateFunction) {
        this.appConfigState = appConfigState;
        this.localeState = localeState;
        this.sessionState = sessionState;
        this.checkoutFormState = checkoutFormState;
        this.shoppingCartItemsState = shoppingCartItemsState;
        this.navigate = navigate;
        this.siteLayoutState = siteLayoutState;
        this.storefrontSettingsState = storefrontSettingsState;
        this.translate = translate;
    }

    private saveToLocalStorage() {
        const values = this.checkoutFormState.get();
        const schema = new CheckoutFormLsModel();

        // shallow copy only the keys defined in schema from the properties in values, so we can extract only the
        const obj = Object.assign(
            schema,
            ...Object.keys(schema).map(
                (key) => ({ [key]: (schema as any)[key] })
            ),
            ...Object.keys(schema).map(
                (key) => ({ [key]: (values as any)[key] })
            )
        );
        let json = '';
        if (obj) {
            json = JSON.stringify(obj);
        }
        window.localStorage.setItem(storefront_checkoutform_key, json);
    }

    reset(catalogId?: string) {
        const whitelist = ['dealIdValue', 'warehouseIdValue', 'locationIdValue'];
        const checkoutFormLsJson = window.localStorage.getItem(storefront_checkoutform_key);
        let cleared = false;
        // try to clear all but white-listed properties
        if (checkoutFormLsJson) {
            try {
                const checkoutFormLs = JSON.parse(checkoutFormLsJson) as CheckoutFormLsModel;
                if (checkoutFormLs) {
                    for (let key in checkoutFormLs) {
                        const whiteListed = whitelist.indexOf(key) !== -1;
                        if (!whiteListed) {
                            delete (checkoutFormLs as any)[key];
                        }
                    }

                    // default the dates
                    const today = dayjs().startOf('day');
                    checkoutFormLs.fromDateValue = today.format('YYYY-MM-DD');
                    checkoutFormLs.toDateValue = dayjs(checkoutFormLs.fromDateValue).add(6, 'day').format('YYYY-MM-DD');
                    window.localStorage.setItem(storefront_checkoutform_key, JSON.stringify(checkoutFormLs));

                    this.checkoutFormState.fromDateError.set('');
                    this.checkoutFormState.fromDateHasError.set(false);
                    this.checkoutFormState.fromDateRequiredError.set('');
                    this.checkoutFormState.fromDateValue.set(checkoutFormLs.fromDateValue);
                    const fromDateByCatalogId = this.checkoutFormState.fromDateByCatalogId.get();
                    if (catalogId && fromDateByCatalogId[catalogId]) {
                        this.checkoutFormState.fromDateByCatalogId[catalogId].set(none);
                    } else {
                        this.checkoutFormState.fromDateByCatalogId.set({});
                    }

                    this.checkoutFormState.toDateError.set('');
                    this.checkoutFormState.toDateHasError.set(false);
                    this.checkoutFormState.toDateRequiredError.set('');
                    this.checkoutFormState.toDateValue.set(checkoutFormLs.toDateValue);
                    if (catalogId && fromDateByCatalogId[catalogId]) {
                        this.checkoutFormState.toDateByCatalogId[catalogId].set(none);
                    } else {
                        this.checkoutFormState.toDateByCatalogId.set({});
                    }
                    cleared = true;
                }
            }
            catch {

            }
        }
        // if the above strategy didn't work then just clear the whole item
        if (!cleared) {
            window.localStorage.removeItem(storefront_checkoutform_key);
        }

        this.checkoutFormState.quoteDescription.set('');
        this.checkoutFormState.jobName.set('');
        this.checkoutFormState.notesValue.set('');

        this.checkoutFormState.isValid.set(false);
        this.checkoutFormState.hasSubmitError.set(false);
        this.checkoutFormState.submitError.set('');
        this.checkoutFormState.isSubmitted.set(false);
        this.checkoutFormState.isSubmitting.set(false);
    }

    // from date
    getFromDate(catalogid?: string): string {
        let result = this.checkoutFormState.get().fromDateValue;
        const separateQuoteRequestsByCatalog = this.storefrontSettingsState.get().settings?.SeparateQuoteRequestsByCatalog ?? false;
        if (catalogid && separateQuoteRequestsByCatalog) {
            const fromDateByCatalogIds = this.checkoutFormState.get().fromDateByCatalogId;
            const fromDateByCatalogId = fromDateByCatalogIds[catalogid];
            if (fromDateByCatalogId !== undefined) {
                result = fromDateByCatalogId;
            }
        }
        return result;
    }
    setFromDate(value: string, catalogid?: string) {
        const separateQuoteRequestsByCatalog = this.storefrontSettingsState.get().settings?.SeparateQuoteRequestsByCatalog ?? false;
        if (!separateQuoteRequestsByCatalog || !catalogid) {
            this.checkoutFormState.fromDateValue.set(value);
            this.saveToLocalStorage();
            //this.validateStartDate();
        }
        else {
            this.checkoutFormState.fromDateByCatalogId.set(d => {
                d[catalogid] = value;
                return d;
            });
            this.saveToLocalStorage();
        }
    }

    // to date
    getToDate(catalogid?: string): string {
        let result = this.checkoutFormState.get().toDateValue;
        const separateQuoteRequestsByCatalog = this.storefrontSettingsState.get().settings?.SeparateQuoteRequestsByCatalog ?? false;
        if (catalogid && separateQuoteRequestsByCatalog) {
            const toDateByCatalogIds = this.checkoutFormState.get().toDateByCatalogId;
            const toDateByCatalogId = toDateByCatalogIds[catalogid];
            if (toDateByCatalogId !== undefined) {
                result = toDateByCatalogId;
            }
        }
        return result;
    }
    setToDate(value: string, catalogid?: string) {
        const separateQuoteRequestsByCatalog = this.storefrontSettingsState.get().settings?.SeparateQuoteRequestsByCatalog ?? false;
        if (separateQuoteRequestsByCatalog) {
            if (catalogid) {
                this.checkoutFormState.toDateByCatalogId.set(d => {
                    d[catalogid] = value;
                    console.log({
                        d: d
                    })
                    return d;
                });
                this.saveToLocalStorage();
            }
        }
        else {
            this.checkoutFormState.toDateValue.set(value);
            this.saveToLocalStorage();
        }
    }

    setDates(fromDate: string, toDate: string) {
        this.checkoutFormState.merge({
            fromDateValue: fromDate,
            toDateValue: toDate
        });
        this.saveToLocalStorage();
    }

    setLocationId(value: string) {
        this.checkoutFormState.locationIdValue.set(value);
        const warehouseLocation = this.checkoutFormState.lookuplocation.get()?.find(l => l.LocationId == value);
        if (warehouseLocation) {
            this.setWarehouseId(warehouseLocation.WarehouseId);
        }
        else {
            this.setWarehouseId('');
        }
        this.saveToLocalStorage();
    }

    setWarehouseId(value: string) {
        this.checkoutFormState.warehouseIdValue.set(value);
        this.saveToLocalStorage();
    }

    setNotes(value: string) {
        this.checkoutFormState.notesValue.set(value);
        this.saveToLocalStorage();
    }

    setSelectedDealId(value: string | undefined) {
        this.checkoutFormState.dealIdValue.set(value ?? '');
        this.saveToLocalStorage();
    }

    setQuoteDescription(value: string) {
        this.checkoutFormState.quoteDescription.set(value);
        this.saveToLocalStorage();
    }

    setJobName(value: string) {
        this.checkoutFormState.jobName.set(value);
        this.saveToLocalStorage();
    }

    setProgressMeterSessionId(value: string | undefined) {
        this.checkoutFormState.progressMeterSessionId.set(value);
    }

    setProgressMeterOpen(value: boolean) {
        this.checkoutFormState.progressMeterOpen.set(value);
    }

    getWarehouse(): Warehouse | undefined {
        let location: WarehouseLocation | undefined;
        const warehouseId = this.checkoutFormState.get().warehouseIdValue;
        const locations = this.checkoutFormState.get().lookuplocation;
        //const isLoadingWarehouses = this.checkoutFormState.get().isLoadingWarehouse;
        if ((warehouseId !== undefined) && (locations !== undefined)) {
            location = locations.find(l => l.WarehouseId === warehouseId);
        }
        if (location) {
            const warehouse: Warehouse = {
                WarehouseId: location.WarehouseId,
                Warehouse: location.Warehouse
            }
            return warehouse;
        }
        return undefined;
    }

    getLocation(): WarehouseLocation | undefined {
        let location: WarehouseLocation | undefined;
        const locationId = this.checkoutFormState.get().locationIdValue;
        const locations = this.checkoutFormState.get().lookuplocation;
        //const isLoadingWarehouses = this.checkoutFormState.get().isLoadingWarehouse;
        if ((locationId !== undefined) && (locations !== undefined)) {
            location = locations.find(w => w.LocationId === locationId);
        }
        return location;
    }

    async loadDealsAsync() {
        let getDealsResponse;
        try {
            if (this.sessionState.get().loggedIn && this.appConfigState.get().appConfig && !this.checkoutFormState.get().deals && !this.checkoutFormState.get().isLoadingDeals) {
                this.checkoutFormState.isLoadingDeals.set(true);
                getDealsResponse = await axios.get<Deal[]>(`${this.appConfigState.get().appConfig?.BaseUrl}/api/v1/storefront/lookupdeal`, {
                    headers: {
                        Authorization: 'Bearer ' + this.sessionState.get().authToken
                    }
                });
                //console.log(getDealsResponse);
                this.checkoutFormState.deals.set(getDealsResponse.data);
                if (getDealsResponse.data && getDealsResponse.data.length > 0) {
                    const deals = getDealsResponse.data;
                    const selectedDealId = this.checkoutFormState.get().dealIdValue;
                    let selectedDeal;
                    if (selectedDealId !== undefined) {
                        selectedDeal = deals.find(o => o.DealId === selectedDealId);
                    }
                    if (!selectedDeal) {
                        this.setSelectedDealId(getDealsResponse.data[0].DealId);
                    }
                } else {
                    this.setSelectedDealId(undefined);
                }
            }
        }
        catch (error: any) {
            //console.log(error);
            if (error && error.response && (error.response.status === 401 || error.response.status === 403)) {
                enqueueSnackbar(`Your session has expired.`, { variant: 'error' });
                await this.siteLayoutState.logoutAsync(false);
                this.siteLayoutState.setLoginFormPopupVisible(true);
                return;
            }
            this.checkoutFormState.isLoadingDealsFailed.set(true);
            this.siteLayoutState.showError(error);
        }
        finally {
            this.checkoutFormState.isLoadingDeals.set(false);
        }
    }


    validateStartDate(newState?: Partial<CheckoutFormModel>) {
        let startDateHasError = false;
        let startDateError: string = '';
        if (!startDateHasError && !this.checkoutFormState.get().fromDateValue)
        {
            startDateHasError = true;
            startDateError = this.checkoutFormState.get().fromDateRequiredError;
        }
        if (startDateHasError) {
            let updateState: boolean = !newState;
            if (!newState) {
                newState = newState ?? {};
            }
            newState.fromDateHasError = startDateHasError;
            newState.fromDateError = startDateError;
            if (updateState) {
                this.checkoutFormState.merge(newState);
            }
        };
    }

    validateStopDate(newState?: Partial<CheckoutFormModel>) {
        let stopDateHasError = false;
        let stopDateError: string = '';
        if (!stopDateHasError && !this.checkoutFormState.get().toDateValue)
        {
            stopDateHasError = true;
            stopDateError = this.checkoutFormState.get().toDateRequiredError;
        }
        if (stopDateHasError) {
            let updateState: boolean = !newState;
            if (!newState) {
                newState = newState ?? {};
            }
            newState.toDateHasError = stopDateHasError;
            newState.toDateError = stopDateError;
            if (updateState) {
                this.checkoutFormState.merge(newState);
            }
        };
    }

    validateForm(): void {
        // validate the login form, triggering a mutation to the newLoginState only if it needs to change
        const newState: Partial<CheckoutFormModel> = {};
        this.validateStartDate(newState);
        this.validateStopDate(newState);

        // update isValid and showValidationError states
        newState.isValid = !newState.fromDateHasError && !newState.toDateHasError;
        //newState.showValidationError = newState.isValid;

        // update the login form state
        if (Object.keys(newState).length > 0) {
            this.checkoutFormState.merge(newState);
        }
    }

    //getIsoDate(date: string): string {
    //    var d = new Date(date);
    //    const isoDateStr: string = [
    //        d.getFullYear(),
    //        ('0' + (d.getMonth() + 1)).slice(-2),
    //        ('0' + d.getDate()).slice(-2)
    //    ].join('-');
    //    return isoDateStr;
    //}

    async submitQuoteRequestAsync(catalogId?: string): Promise<void> {
        try {
            this.checkoutFormState.isSubmitting.set(true);
            // copy the state objects so we can use them in the web request
            const checkoutFormStateCopy = clone(this.checkoutFormState.get()) as CheckoutFormModel;
            const shoppingCartItemsCopy = clone(this.shoppingCartItemsState.get()) as ShoppingCartItemModel[];

            const quoteRequest = new ApiQuoteRequest();
            quoteRequest.WebCatalogId = catalogId;
            quoteRequest.Language = this.localeState.get().language;
            quoteRequest.DepartmentId = shoppingCartItemsCopy[0].product.InventoryDepartmentId;
            quoteRequest.LocationId = checkoutFormStateCopy.locationIdValue;
            quoteRequest.WarehouseId = checkoutFormStateCopy.warehouseIdValue;
            if (checkoutFormStateCopy.dealIdValue === '-') {
                quoteRequest.DealId = '';
            }
            else {
                quoteRequest.DealId = checkoutFormStateCopy.dealIdValue;
            }
            quoteRequest.QuoteDescription = checkoutFormStateCopy.quoteDescription;
            quoteRequest.JobName = checkoutFormStateCopy.jobName;
            quoteRequest.StartDate = dayjs(checkoutFormStateCopy.fromDateValue).format('YYYY-MM-DD');
            quoteRequest.StopDate = dayjs(checkoutFormStateCopy.toDateValue).format('YYYY-MM-DD');
            quoteRequest.Notes = checkoutFormStateCopy.notesValue;
            quoteRequest.Items = [];
            for (let i = 0; i < shoppingCartItemsCopy.length; i++) {
                const shoppingCartItem = shoppingCartItemsCopy[i];
                if ((catalogId === undefined) || (shoppingCartItem.product.WebCatalogId === catalogId)) {
                    const quoteRequestItem: ApiQuoteRequestItem = {
                        InventoryId: shoppingCartItem.product.InventoryId,
                        WebCatalogId: shoppingCartItem.product.WebCatalogId,
                        Qty: shoppingCartItem.qty
                    };
                    for (let key in shoppingCartItem.packageItemQtys) {
                        if (shoppingCartItem.product.PackageItems) {
                            const shoppingCartPackageItem = shoppingCartItem.product.PackageItems.find(pi => pi.InventoryId === key);
                            if (shoppingCartPackageItem) {
                                const shoppingCartPackageItemQty = shoppingCartItem.packageItemQtys[key];
                                const quoteRequestPackageItem: ApiQuoteRequestPackageItem = {
                                    Lineage: shoppingCartItem.product.Id,
                                    InventoryId: shoppingCartPackageItem.InventoryId,
                                    InventoryPackageInventoryId: shoppingCartPackageItem.InventoryPackageInventoryId,
                                    Qty: shoppingCartPackageItemQty
                                };
                                if (!quoteRequestItem.PackageItems) {
                                    quoteRequestItem.PackageItems = [];
                                }
                                quoteRequestItem.PackageItems.push(quoteRequestPackageItem);
                            }
                        }
                    }
                    quoteRequest.Items.push(quoteRequestItem);
                }
            }
            //console.log('quoteRequest:', quoteRequest);
            try {
                const quoteRequestResponse = await axios.post<QuoteRequestResponse>(`${this.appConfigState.get().appConfig?.BaseUrl}/api/v1/storefront/submitquoterequest`, quoteRequest, {
                    headers: {
                        Authorization: 'Bearer ' + this.sessionState.get().authToken
                    }
                });
                if (quoteRequestResponse.data.Success && quoteRequestResponse.data.ProgressMeterSessionId) {
                    this.setProgressMeterSessionId(quoteRequestResponse.data.ProgressMeterSessionId);
                    this.setProgressMeterOpen(true);
                }
                else {
                    this.checkoutFormState.hasSubmitError.set(true);
                    this.checkoutFormState.submitError.set('Invalid credentials.');
                }
            }
            catch (error: any) {
                if ((error as AxiosError)?.response?.status === 400) {
                    this.checkoutFormState.hasSubmitError.set(true);
                    this.checkoutFormState.submitError.set('Bad request. Make sure sure form is valid.');
                    this.siteLayoutState.showError(error);
                }
                else if ((error as AxiosError)?.response?.status === 401) {
                    this.siteLayoutState.logoutAsync();
                    this.siteLayoutState.setLoginFormPopupVisible(true);
                }
                else if ((error as AxiosError)?.response?.status === 403) {
                    this.siteLayoutState.logoutAsync();
                    this.siteLayoutState.setLoginFormPopupVisible(true);
                }
                else if ((error as AxiosError)?.response?.status === 500) {
                    this.checkoutFormState.hasSubmitError.set(true);
                    this.checkoutFormState.submitError.set('An an occured on the server.');
                }
                else {
                    this.siteLayoutState.showError(error);
                }
            }
            
            //if (quoteRequestResponse.status === 200) {
            //    if (quoteRequestResponse.data.Success && quoteRequestResponse.data.ProgressMeterSessionId) {
            //        this.setProgressMeterSessionId(quoteRequestResponse.data.ProgressMeterSessionId);
            //        this.setProgressMeterOpen(true);

            //        // need to do this elsewhere after progress meter is complete
            //        //this.checkoutFormState.isSubmitted.set(true);
            //        //this.checkoutFormState.hasSubmitError.set(false);
            //        //this.checkoutFormState.submitError.set('');
            //        //this.shoppingCartItemsState.reset();
            //        //enqueueSnackbar(this.translate(`Quote request submitted`), {
            //        //    variant: 'success'
            //        //});
            //        //this.reset();
            //        //this.navigate('/');
            //        //this.reset();
            //    }
            //    else {
            //        this.checkoutFormState.hasSubmitError.set(true);
            //        this.checkoutFormState.submitError.set('Invalid credentials.');
            //    }
            //}
            //if (quoteRequestResponse.status === 400) {
            //    this.checkoutFormState.hasSubmitError.set(true);
            //    this.checkoutFormState.submitError.set('Bad request. Make sure sure form is valid.');
            //}
            //else if (quoteRequestResponse.status === 401) {
            //    this.siteLayoutState.logoutAsync();
            //    this.siteLayoutState.setLoginFormPopupVisible(true);
            //}
            //else if (quoteRequestResponse.status === 403) {
            //    this.siteLayoutState.logoutAsync();
            //    this.siteLayoutState.setLoginFormPopupVisible(true);
            //}
            //else if (quoteRequestResponse.status === 500) {
            //    this.checkoutFormState.hasSubmitError.set(true);
            //    this.checkoutFormState.submitError.set('An an occured on the server.');
            //}
            //console.log('quoteRequestResponse:', quoteRequestResponse);
        } catch (error) {
            this.siteLayoutState.showError(error);
        }
        finally {
            this.checkoutFormState.isSubmitting.set(false);
        }
    }

    async loadLocationsAsync(): Promise<void> {
        try {
            if (this.appConfigState.get().appConfig && !this.checkoutFormState.get().isLoadingLocation) {
                this.checkoutFormState.isLoadingLocation.set(true);
                const axiosRequestConfig: AxiosRequestConfig = {};
                if (this.sessionState.get().loggedIn) {
                    axiosRequestConfig.headers = {
                        Authorization: 'Bearer ' + this.sessionState.get().authToken
                    }
                }
                const lookuplocationResponse = await axios.get<WarehouseLocation[]>(`${this.appConfigState.get().appConfig?.BaseUrl}/api/v1/storefront/lookuplocation`, axiosRequestConfig);
                //const locationid = this.quoteRequestFormState.get().locationIdValue;

                this.checkoutFormState.lookuplocation.set(lookuplocationResponse.data);
                //if (lookuplocationResponse.data.length > 0) {
                //    if (!locationid) {
                //        this.setLocationId(lookuplocationResponse.data[0].LocationId);
                //    }
                //}
            }
        }
        finally {
            this.checkoutFormState.isLoadingLocation.set(true);
        }
    }

    async loadWarehousesAsync(): Promise<void> {
        try {
            if (this.appConfigState.get().appConfig && !this.checkoutFormState.get().isLoadingWarehouse && !this.checkoutFormState.get().lookupwarehouse) {
                this.checkoutFormState.isLoadingWarehouse.set(true);
                const axiosRequestConfig: AxiosRequestConfig = {};
                if (this.sessionState.get().loggedIn) {
                    axiosRequestConfig.headers = {
                        Authorization: 'Bearer ' + this.sessionState.get().authToken
                    }
                }
                const lookupwarehouseResponse = await axios.get<Warehouse[]>(`${this.appConfigState.get().appConfig?.BaseUrl}/api/v1/storefront/lookupwarehouse`, axiosRequestConfig);
                this.checkoutFormState.lookupwarehouse.set(lookupwarehouseResponse.data);
                //const warehouseid = this.quoteRequestFormState.get().warehouseIdValue;

                this.checkoutFormState.lookupwarehouse.set(lookupwarehouseResponse.data);
                //if (lookupwarehouseResponse.data.length > 0) {
                //    if (!warehouseid) {
                //        this.setWarehouseId(lookupwarehouseResponse.data[0].WarehouseId)
                //    }
                //}
            }
        }
        finally {
            this.checkoutFormState.isLoadingWarehouse.set(true);
        }
    }

    async updateDatesAsync(): Promise<void> {
        
    }

    getDurationInDays(): number {
        let fromDate: dayjs.Dayjs | undefined, toDate: dayjs.Dayjs | undefined;
        if (this.checkoutFormState.get().fromDateValue) {
            fromDate = dayjs(this.checkoutFormState.get().fromDateValue);
        }
        if (this.checkoutFormState.get().toDateValue) {
            toDate = dayjs(this.checkoutFormState.get().toDateValue);
        }
        let durationDays = 0;
        if (fromDate !== undefined && toDate !== undefined) {
            durationDays = toDate.diff(fromDate, 'day') + 1;
        }
        return durationDays;
    }

    setSubmitComplete(catalogId?: string): void {
        this.checkoutFormState.progressMeterSessionId.set(undefined);
        this.checkoutFormState.isSubmitted.set(true);
        this.checkoutFormState.hasSubmitError.set(false);
        this.checkoutFormState.submitError.set('');
        this.shoppingCartItemsState.reset(catalogId);
        enqueueSnackbar(this.translate(`Quote request submitted`), {
            variant: 'success'
        });
        this.reset(catalogId);
        this.navigate('/');
        //this.reset();
    }

    setSubmitFailed(): void {
        this.checkoutFormState.progressMeterSessionId.set(undefined);
    }

    isFormDisabled(): boolean {
        return this.checkoutFormState.get().isSubmitted || this.checkoutFormState.get().isSubmitting;
    }
}

export type WarehouseLocation = {
    Id: string; // locationid~warehouseid
    LocationId: string;
    Location: string;
    WarehouseId: string;
    Warehouse: string;
}

export type Warehouse = {
    WarehouseId: string;
    Warehouse: string;
}

type QuoteRequestResponse = {
    Success: boolean;
    FailureMessage: string;
    ProgressMeterSessionId: string;
}

export function useCheckoutFormState(): CheckoutFormState {
    const appConfigState = useAppConfigState();
    const localeState = useLocaleState();
    const sessionState = useSessionState();
    const checkoutFormState = useHookstate(checkoutFormGlobalStore)
    const shoppingCartItemsState = useShoppingCartItemsState();
    const navigate = useNavigate();
    const siteLayoutState = useSiteLayoutState();
    const storefrontSettingsState = useStorefrontSettingsState();
    const translate = useTranslate();
    return new CheckoutFormState(appConfigState, localeState, sessionState, checkoutFormState, shoppingCartItemsState, navigate, siteLayoutState, storefrontSettingsState, translate);
}
